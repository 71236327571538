const routes = {
    mainRoute: 'atd',
    preSignIn: 'pre-signin',
    signIn: 'signin',
    forgotPassword: 'forgot-password',
    signup: 'sign-up',
    resetPassword: 'reset-password',
    notAuthorize: 'not-authorize',
    containers: {
        ion: 'ion',
        io: 'io',
        admin: 'admin',
        audience: 'audience',
        // campaigns: 'campaign',
        creative: 'creative',
        dashboard: 'dashboard',
        display: 'display',
        video: 'video',
        native: 'native',
        audio: 'audio',
        mediaPlan: "mediaplan",
        advertiser: "advertiser",
        goals:"goals",
        ro: 'goals-list',
        unmappedCampaign: 'unmapped-campaign',
        optimizer: "optimizer",
        margin: 'margin',
        adminMargin: 'adminMargin',
        campaignList: "campaign",
        campaignName: "campaignNew",
        dataConfig: 'data-config',
        audience: "audience",
        payment: 'payment',
        errors: "errors"
    },
    dataConfig: {
        platformVaraible: {
            name: 'platformvariablesmapping',
            addPlatformVariablesMapping: 'add',
            editPlatformVariablesMapping: 'edit',
        },
        dspPlatform: {
            name: 'dsp-platform',
            addDspPlatform: 'add',
            editDspPlatform: 'edit',
        },
        variables: {
            name: 'variables',
            addVariables: 'add-variables',
            editVariables: 'edit-variables',
        },
        platformTenantAdvertiser: {
            name: 'platformtenantadvertisermaping',
            addPlatformTenantAdvertiser: 'add',
            editPlatformTenantAdvertiser: 'edit',
        },
        

    },






    admin: {
        advertiser: {
            name: 'advertiser',
            addAdvertiser: 'add-advertiser',
            editAdvertiser: 'edit-advertiser',
            poc: "advertiser-poc",
            
            dev: "advertiser-add",
            newAdvertiser: "advertiser-list",
            newAdvertiserAdd: "add-advertiser"
        },
        tenant: {
            name: 'tenant',
            add: 'add-tenant',
            edit: 'edit-tenant',
            editMgr: 'edit-mgr-tenant',
            view: 'view-tenant',
            viewMgr: 'view-mgr-tenant',
            addMgr: "add-mgr-tenant"
        },
        supplyPartner: {
            name: 'supply-partner',
            add: 'add-supply-partner',
            edit: 'edit-supply-partner'
        },
        platform: {
            name: 'platform',
            add: 'add-platform',
            edit: 'edit-platform'
        },
        account: {
            name: 'account',
            add: 'add-account',
            edit: 'edit-account',
            view: 'view-account'
        },
        currencyRate: {
            name: 'currency-rate',
            add: 'add-currency-rate',
            edit: 'edit-currency-rate'
        },
        features: {
            name: 'features'
        },
        variable: {
            name: 'variable',
            add: 'add-variable',
            edit: 'edit-variable'
        },
        user: {
            name: 'user',
            add: 'add-user',
            edit: 'edit-user'
        },
        demandPartner: {
            name: 'demand',
            add: 'add-demand',
            edit: 'edit-demand'
        },
        roles: {
            name: 'roles',
            add: 'add-roles',
            edit: 'edit-roles'
        },
        supplyPath: {
            name: 'supply-path',
        },
        agreement: {
            name: 'agreement',
            add: 'add-agreement',
            edit: 'edit-agreement'
        },


    },


    campaign: {
        add: 'add-campaign',
        edit: 'edit-campaign'
    },

    campaignTest: {
        add: 'add-campaign',
        edit: 'edit-campaign'
    },

    margin: {
        addMargin: 'add-margin',
        editMargin: 'edit-margin'
    },
    adminMargin: {
        addAdminMargin: 'add-admin-margin',
        editAdminMargin: 'edit-admin-margin'
    },

    creative: {
        display: {
            name: 'display',
            add: 'add-display',
            edit: 'edit-display',
        },
        video: {
            name: 'video',
            add: 'add-video',
            edit: 'edit-video',
        },
        native: {
            name: 'native',
            add: 'add-native',
            edit: 'edit-native',
        },
        audio: {
            name: 'audio',
            add: 'add-audio',
            edit: 'edit-audio',
        }
    },
    io: {
        addIo: 'add-io',
        editIo: 'edit-io',
    },
    optimizer: {
        recommendations: 'recommendations',
        // advertiser: 'advertiser'
    },
    mediaPlan: {
        addMedia: 'add-media',
        mediaPlanPDF: 'pdf',
        editMedia: 'edit-media',
    },
    ro: {
        addRo: 'add-goal',
        editRo: 'edit-goal',
        viewRo: 'view-goal'
    },
    unmappedCampaign: {
        crateRO: 'create-goal',
        mapRO: 'map-goal'
    },


    ion: {
        adUnit: {
            name: 'adunit',
            add: 'add-adunit',
            edit: 'edit-adunit',
        },
        adUnitGroup: {
            name: 'adunit-group',
            add: 'add-adunit-group',
            edit: 'edit-adunit-group',
        },
        privateMarketPlace: {
            name: 'private-market-place',
            add: 'add-private-market-place',
            edit: 'edit-private-market-place',
            addSeat: 'add-seat'
        },
        mediation: {
            name: 'mediation',
            add: 'add-mediation',
            edit: 'edit-mediation',
            addRule: 'add-mediation-rule'
        },
        mediationAdGroup: {
            name: 'mediation-add-group',
            add: 'add-mediation-add-group',
            edit: 'add-mediation-edit-group',
        },
        tagldMapping: {
            tagldMapping: 'tagld-mapping',
            tagldMappingList: 'tagld-mapping-list',
            tagldMappingBulkUpload: 'tagld-mapping-bulk-upload',
            add: 'add-tagld-mapping',
        },
    },
    // dashboard: {
    //     atd: {
    //         atd: 'atd',
    //         details: 'details'
    //     },
    //     bidder: {
    //         bidder: 'bidder',
    //         campaignPerformanceReport: 'campaign-performance-report',
    //         bidderReport: 'bidder-report',
    //         mediaPlanningReport: 'media-planning-report'
    //     },
    //     ion: {
    //         ionReport: 'ion-report',
    //         mediaReport: 'media-report',
    //         revenueReport: 'revenue-report',
    //         qpsReport: 'qps-report',
    //         ionExchange: 'ion-exchange',
    //     }
    // },
    audience: {
        audienceHierarchy: {
            name: 'audience-hierarchy'
        },
        hierarchyManager: {
            name: 'hierarchy-manager'
        },
        apr: {
            name: 'apr',
            add: 'add-apr',
            edit: 'edit-apr'
        },
        taxonomyManager: {
            name: 'taxonomy-manager',
            add: 'add-taxonomy-manager',
            edit: 'edit-taxonomy-manager'
        },
        dataCapture: {
            name: 'data-capture',
            add: 'add-data-capture',
            edit: 'edit-data-capture'
        },
    },
    payment: {
        addPayment: {
            name: 'add-payment',
            response:"response",
        },
        billingHistory: {
            name: 'billing-history',
        },
        invoice: {
            name: 'invoice',
        },
        subscription : {
            name: 'subscription',
        },
        demo: {
            name: 'paypal-transaction'
        }
    },

};

export default routes;