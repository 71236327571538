export const apiBaseUrl = window.location.protocol === 'https:' ? 'https://stageatdv3.torcai.com' : 'https://stageatdv3.torcai.com';
export const apiFileUploadBaseUrl = 'https://stageatdv3.torcai.com/atd';
export const authBaseUrl = 'https://stageatdv3.torcai.com'
export const demoTenantBaseUrl = "https://atdtestdemo.torcai.com";
export const version = '1.0.0';
export const domain = 'torcai.in';
export const constants = {};
export const tenantIdsToShowDemoData = [535,536];

// export const soundTrackBaseUrl = window.location.protocol === 'https:' ? 'https://stagepublisherV2.torcai.com' : 'http://stagepublisherV2.torcai.com';
export const soundTrackBaseUrl = window.location.protocol === 'https:' ? 'https://stageatdv2.torcai.com' : 'https://stageatdv2.torcai.com';

export const endPoints = {
  atd: '/report-atd',
  atdDashboard : '/report-atd/dashboard',
  auth: '/auth/v3',
  geo: '/tai/geo/torcai',
  bidder: '/bidder/report',
  io: '/io',
  campaign: '/campaign',
  campaignPmp: '/campaign-pmp',
  creative: '/creative',
  medialist: '/medialist',
  data: '/data',
  ionReport: '/ion/report',
  adUnitBulk: '/adunitbulk',
  adUnitMap: '/adunitmap',
  mediation: '/mediation',
  pmp: '/pmp',
  adgroup: '/adgroup',
  adunit: '/adunit',
  payment: "/payment",
  fb: '/fb',
  subscription: '/subscription',
  tiktok:'/tiktok'
}
export const cdnUrl = window.location.protocol === 'https:' ? 'https://d1g8vw470g7bmg.cloudfront.net' : 'http://d1g8vw470g7bmg.cloudfront.net';

export const CONTEXT = '/atd';
// export const torcaiTracking = apiBaseUrl + '/audtrack/postback/track?'
export const torcaiTracking = soundTrackBaseUrl + '/audtrack/postback/track?'
// export const tomcatApiUrl = apiBaseUrl.replace(window.location.protocol, "") + '/content/dmp';
export const tomcatApiUrl = cdnUrl.replace(window.location.protocol, "") + '/dmp/ifrm';
// export const iFrameApiUrl = apiBaseUrl + '/dmp/jsv2';
export const iFrameApiUrl = cdnUrl + '/dmp/ifrm';
// export const imageApiUrl = apiBaseUrl + '/audtrack/param/track?';
export const imageApiUrl = soundTrackBaseUrl + '/audtrack/param/track?';