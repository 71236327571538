import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import routes from '../routes';

export const checkPermission = ({ permissions = [] }) => {
  let userPermissions = ((localStorage.getItem('getUserPermissionList') && JSON.parse(localStorage.getItem('getUserPermissionList'))) || []);

  return permissions.find(a => userPermissions.indexOf(a) > -1);
};

export const getDefaultRoute = (defaultRoute) => {
  let route = ((localStorage.getItem('defaultRoute') && JSON.parse(localStorage.getItem('defaultRoute'))) || {});
  let mainRoute = ((localStorage.getItem('defaultMainRoute') && JSON.parse(localStorage.getItem('defaultMainRoute'))) || {});
  return (route[defaultRoute] || mainRoute);
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    checkPermission(rest)
      ? <Component {...props} {...rest}/>
      : getDefaultRoute(rest.defaultRoute) ? <Redirect to={{
        pathname: `${getDefaultRoute(rest.defaultRoute)}`,
        state: { from: props.location }
      }} /> : <Redirect to={{
        pathname: `/${routes.mainRoute}/${routes.notAuthorize}`,
        state: { from: props.location }
      }} />
  )} />
);


export const checkTablePermissionForRedirectDashboard = (permission, options) => {

  let userPermission = ((localStorage.getItem('getUserPermissionList') && JSON.parse(localStorage.getItem('getUserPermissionList'))) || []);

  if (!userPermission.find((a) => a == permission.dashboard)) {
    options.actions = options.actions.filter((a) => a.type != 'chart');
  }

  return options;

};


export const checkTablePermission = (permission, options) => {

  let userPermission = ((localStorage.getItem('getUserPermissionList') && JSON.parse(localStorage.getItem('getUserPermissionList'))) || []);

  if (!userPermission.find((a) => a == permission.edit)) {
    options.actions = options.actions.filter((a) => a.type != 'edit');
    options.actions = options.actions.filter((a) => a.type != 'viewRule');
    options.actions = options.actions.filter((a) => a.type != "clone")
    options.recActive = false;
    options.actionsThead = false;
  }

  if (!userPermission.find((a) => a == permission.selection)) {
    if (options.selection) {
      options.selection.show = false;
    }
  }

  if (!userPermission.find((a) => a == permission.delete)) {
    options.actions = options.actions.filter((a) => a.type != 'delete');
  }

  return options;

};


export const checkAddPermission = (type) => {
  let userPermission = ((localStorage.getItem('getUserPermissionList') && JSON.parse(localStorage.getItem('getUserPermissionList'))) || []);
  return userPermission.find((a) => a == type);
};
