// added namespacing to avoid interference with other plugins or redux stores

export const POST_SIGN_UP = "POST_SIGN_UP"


export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";

export const END_AJAX_CALL = "END_AJAX_CALL";

export const PRE_SIGN_IN_SUCCESS = "PRE_SIGN_IN_SUCCESS";

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";

export const DISPATCH_PRE_SIGNIN_STATE_SUCCESS = "DISPATCH_PRE_SIGNIN_STATE_SUCCESS";

export const GET_CONFIGURATION_DATA = "GET_CONFIGURATION_DATA";

export const GET_FILTER_LIST = "GET_FILTER_LIST";

export const GET_ION_FILTER_LIST = "GET_ION_FILTER_LIST";

export const PERTICULAR_WORLD_MAP_DATA = 'PERTICULAR_WORLD_MAP_DATA'

export const POST_WORLD_MAP_DATA = "POST_WORLD_MAP_DATA";

export const LIST_ALL_PROFILE = "LIST_ALL_PROFILE";

export const PAYMENT_CREDIT = "PAYMENT_CREDIT";

export const CURRENT_BALANCE_AMMOUNT = "CURRENT_BALANCE_AMMOUNT";

export const CREDIT_BALANCE_AMMOUNT = "CREDIT_BALANCE_AMMOUNT";

export const FETCH_ALL_TENANTS_BALANCE = "FETCH_ALL_TENANTS_BALANCE";


// *****************Country and currency related Action type Starts here****************

export const GET_ALL_CURRENCY = "GET_ALL_CURRENCY";

export const GET_ALL_COUNTRYU = "GET_ALL_COUNTRYU";

export const GET_FILTER_CURRENCY = "GET_FILTER_CURRENCY";

export const GET_ALL_CURRENCY_SIGN_UP = "GET_ALL_CURRENCY_SIGN_UP";

export const GET_ALL_COUNTRYU_SIGN_UP = "GET_ALL_COUNTRYU_SIGN_UP";

export const GET_FILTER_CURRENCY_SIGN_UP = "GET_FILTER_CURRENCY_SIGN_UP";



// ATD start here8***********************************************

export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";

export const GET_ADVERTISER_LIST = "GET_ADVERTISER_LIST";

export const GET_CAMPAIGN_CUSTOM = "GET_CAMPAIGN_CUSTOM";

export const POST_CAMPAIGN_PERFORMACE_REPORT = "POST_CAMPAIGN_PERFORMACE_REPORT";

export const POST_CAMPAIGN_PERFORMACE_TOTAL_REPORT = "POST_CAMPAIGN_PERFORMACE_TOTAL_REPORT";

export const POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT = "POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT";

export const POST_CHANNEL_SPEND = "POST_CHANNEL_SPEND";

export const POST_GEO_REPORT = "POST_GEO_REPORT";

export const POST_GEO_CAMPAIGN_ID_REPORT = "POST_GEO_CAMPAIGN_ID_REPORT";

export const POST_CREATIVE_PERFORMANCE_REPORT = "POST_CREATIVE_PERFORMANCE_REPORT";

export const POST_AGE_GENDER_REPORT = "POST_AGE_GENDER_REPORT";

export const POST_AGE_GENDER_CAMPAIGN_ID = "POST_AGE_GENDER_CAMPAIGN_ID";

export const GET_MEDIA_PLAN_LIST = 'GET_MEDIA_PLAN_LIST';

export const POST_CSV_DOWNLOAD = 'POST_CSV_DOWNLOAD'

export const POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT_DOWNLOAD = 'POST_CAMPAIGN_PERFORMACE_DATEWISE_REPORT_DOWNLOAD'

export const POST_CSV_DOWNLOAD_PERFORMANCE_REPORTS = 'POST_CSV_DOWNLOAD_PERFORMANCE_REPORTS';

export const POST_CSV_DOWNLOAD_CREATIVE_REPORTS = 'POST_CSV_DOWNLOAD_CREATIVE_REPORTS';

export const POST_CSV_DOWNLOAD_GEO_REPORTS = 'POST_CSV_DOWNLOAD_GEO_REPORTS';

export const POST_CSV_DOWNLOAD_DOMAIN_REPORTS = 'POST_CSV_DOWNLOAD_DOMAIN_REPORTS';

export const POST_PERFORMANCE_REPORT = 'POST_PERFORMANCE_REPORT';

export const POST_CREATIVE_REPORT_TABLE = 'POST_CREATIVE_REPORT_TABLE';




//    OPTIMIZER SCREEN STARTS HERE ***************************************






export const GET_OPTIMIZER_LIST = 'GET_OPTIMIZER_LIST';

export const GET_OPTIMIZER_RECOMMENDATION_LIST = 'GET_OPTIMIZER_RECOMMENDATION_LIST';

export const GET_RECOMMENDATION_LIST = 'GET_RECOMMENDATION_LIST'

export const POST_UPDATE_RECOMMENDATION = 'POST_UPDATE_RECOMMENDATION'

export const POST_DELETE_RECOMMENDATION = 'POST_DELETE_RECOMMENDATION'






//    OPTIMIZER SCREEN ENDS HERE ***************************************




export const GET_STATUS_PDF = 'GET_STATUS_PDF';

export const GET_AGE_LIST = 'GET_AGE_LIST';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';

export const GET_SUBCATEGORY_LIST = 'GET_SUBCATEGORY_LIST';

export const POST_MEDIA_PLAN_CREATION = 'POST_MEDIA_PLAN_CREATION';



// ATD End  here8***********************************************


// DATA CONFIG SATRTS HERE ==============================


export const GET_DSP_LIST_ALL_DATA = 'GET_DSP_LIST_ALL_DATA';

export const POST_CREATE_DSP_PLATFORM = 'POST_CREATE_DSP_PLATFORM';

export const GET_LIST_BY_ID_DSP_PLATFORM = 'GET_LIST_BY_ID_DSP_PLATFORM';

export const POST_UPDATE_DSP_PLATFORM = 'POST_UPDATE_DSP_PLATFORM';

export const GET_VARIABLE_LIST_ALL_DATA = 'GET_VARIABLE_LIST_ALL_DATA'

export const POST_CREATE_VARIABLE = 'POST_CREATE_VARIABLE';

export const GET_LIST_BY_ID_VARIABLE = 'GET_LIST_BY_ID_VARIABLE';

export const POST_UPDATE_VARIABLE = 'POST_UPDATE_VARIABLE';






// DATA CONFIG ENDS HERE










// -----* ION_REPORT * ----

export const GET_ADD_UNIT_LIST = "GET_ADD_UNIT_LIST";

export const GET_ADD_UNIT_GROUP_LIST = "GET_ADD_UNIT_GROUP_LIST";

export const GET_PMP_LIST = "GET_PMP_LIST";

export const GET_ROADBLOCK_LIST = "GET_ROADBLOCK_LIST";

export const GET_TAGLD_MAPPING_LIST = "GET_TAGLD_MAPPING_LIST";

export const GET_TAGLD_MAPPING_ADUNIT_ALL_LIST = "GET_TAGLD_MAPPING_ADUNIT_ALL_LIST";

export const GET_TAGLD_MAPPING_BULK_LIST = "GET_TAGLD_MAPPING_BULK_LIST";

export const GET_UNMAPPED_CAMPAIGN_LIST = "GET_UNMAPPED_CAMPAIGN_LIST";

// -----* GET_ION_REPORT * ----

export const GET_MEDIA_REPORT = "GET_MEDIA_REPORT";

export const GET_QPS_REPORT = "GET_QPS_REPORT";

export const GET_REVENUE_REPORT = "GET_REVENUE_REPORT";

export const GET_ION_EXCHANGE_REPORT = "GET_ION_EXCHANGE_REPORT";

// ---- * GET_BIDDER_REPORT * ----

export const GET_BIDDER_REPORT = "GET_BIDDER_REPORT";

export const GET_USER_REACH_REPORT = "GET_USER_REACH_REPORT";

export const ADD_TENANT = "ADD_TENANT";

export const GET_MEDIA_PLANNING_REPORT = "GET_MEDIA_PLANNING_REPORT";

export const GET_MEDIA_PLAN_DOWNLOAD = 'GET_MEDIA_PLAN_DOWNLOAD';

export const GET_CAMPAIGN_PERFORMANCE_REPORT = "GET_CAMPAIGN_PERFORMANCE_REPORT";

export const GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST = "GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST";

export const GET_SAVE_REPORT = "GET_SAVE_REPORT";

export const GET_GENERATE_REPORT = "GET_GENERATE_REPORT";

export const GET_REFRESH_REPORT = "GET_REFRESH_REPORT";

export const GET_MEDIA_SAVE_REPORT = "GET_MEDIA_SAVE_REPORT";

export const GET_CAMPAIGN_LIST_ALL_REPORT = "GET_CAMPAIGN_LIST_ALL_REPORT";

export const GET_CAMPAIGN_CATEGORY_TYPE_LIST = "GET_CAMPAIGN_CATEGORY_TYPE_LIST";

// ----* io ---
export const GET_IO_LIST_ALL_REPORT = "GET_IO_LIST_ALL_REPORT";

export const GET_IO_ADVERTISER_LIST_REPORT = "GET_IO_ADVERTISER_LIST_REPORT";

export const GET_EXCHANGE_LIST_REPORT = "GET_EXCHANGE_LIST_REPORT";

// ----* Campaign * -----
export const GET_CAMPAIGN_SUB_CATEGORY = "GET_CAMPAIGN_SUB_CATEGORY";

export const GET_ADVERTISER_CREATIVE_GROUP = "GET_ADVERTISER_CREATIVE_GROUP";

export const POST_CREATIVE_DETAILS = "POST_CREATIVE_DETAILS";

// campaign  Private Market Place
export const GET_PRIVATE_MARKET_PLACE_LIST = "GET_PRIVATE_MARKET_PLACE_LIST";

// ---* country/state/city * ---
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";

export const GET_STATE_LIST = "GET_STATE_LIST";

export const GET_CITY_LIST = "GET_CITY_LIST";

// ---* state all/city all * ---
export const GET_STATE_LIST_ALL = "GET_STATE_LIST_ALL";

export const GET_CITY_LIST_ALL = "GET_CITY_LIST_ALL";

// ---- * Admin * ---

// Tenant
export const GET_TENANT_LIST_ALL = "GET_TENANT_LIST_ALL";

export const GET_ACCOUNTS_LIST = "GET_ACCOUNTS_LIST";

//Supply Partner
export const GET_SUPPLY_PARTNER_LIST_ALL = "GET_SUPPLY_PARTNER_LIST_ALL";

//Variable

// User
export const GET_ADMIN_USER_LIST_ALL = "GET_ADMIN_USER_LIST_ALL";

// roles
export const GET_ADMIN_ROLES_LIST_ALL = "GET_ADMIN_ROLES_LIST_ALL";

//platform
export const GET_PLATFORM_LIST_ALL = "GET_PLATFORM_LIST_ALL";

//  Demand partner name filter
export const GET_DEMAND_PARTNER_NAME_LIST_ALL =
  "GET_DEMAND_PARTNER_NAME_LIST_ALL";

// Demandpartner
export const GET_DEMAND_PARTNER_LIST_ALL = "GET_DEMAND_PARTNER_LIST_ALL";

// Demandpartner Seat
export const GET_DEMAND_PARTNER_SEAT_LIST_ALL = "GET_DEMAND_PARTNER_SEAT_LIST_ALL";

export const GET_TENANT_DEMAND_PARTNER_LIST = "GET_TENANT_DEMAND_PARTNER_LIST";

export const GET_BALANCE ="GET_BALANCE"

export const GET_ADMIN_SUPPLY_PARTNER_LIST_ALL = "GET_ADMIN_SUPPLY_PARTNER_LIST_ALL";

export const GET_TENANT_SUPPLY_PARTNER_LIST_ALL = "GET_TENANT_SUPPLY_PARTNER_LIST_ALL";

// Currency Rate
export const GET_CURRENCY_RATE_LIST = "GET_CURRENCY_RATE_LIST";

export const ADD_CURRENCY_RATE = "ADD_CURRENCY_RATE";

// supplyPath
export const GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL = "GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL";

export const GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL = "GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL";

// feature
export const GET_LIST_FEATURE_ALL = "GET_LIST_FEATURE_ALL";

export const GET_FEATURE_LIST_ALL = "GET_FEATURE_LIST_ALL";

// mediation
export const GET_MEDIATION_LIST = "GET_MEDIATION_LIST";

// agreement
export const GET_AGREEMENT_LIST_ALL = "GET_AGREEMENT_LIST_ALL";

export const GET_AGREEMENT_CONNECTION_LIST = "GET_AGREEMENT_CONNECTION_LIST";

// Add Mediation AdGroup
export const GET_MEDIATION_AD_GROUP_LIST = "GET_MEDIATION_AD_GROUP_LIST";

// user Permission
export const GET_USER_PERMISSION = "GET_USER_PERMISSION";

// ---- * Audience * ---

// Apr
export const GET_APR_LIST_ALL = "GET_APR_LIST_ALL";
export const GET_SEGMENT_LIST_ALL = "GET_SEGMENT_LIST_ALL";
export const GET_HIERARCHY_LIST_ALL = "GET_HIERARCHY_LIST_ALL";
export const GET_DATA_CAPTURE_LIST_ALL = "GET_DATA_CAPTURE_LIST_ALL";
export const GET_AUDIENCE_HIERARCHY_LIST_ALL = "GET_AUDIENCE_HIERARCHY_LIST_ALL";
export const GET_PINCODE_LIST = "GET_PINCODE_LIST";

// campaign site list
export const GET_CAMPAIGN_SITE_LIST_ALL = "GET_CAMPAIGN_SITE_LIST_ALL";
export const GET_CAMPAIGN_PERFORMANCE_REPORT_TREND = "GET_CAMPAIGN_PERFORMANCE_REPORT_TREND";
export const GET_MEDIA_PLANNING_REPORT_TREND = "GET_MEDIA_PLANNING_REPORT_TREND";
export const GET_PINCODE_LIST_ALL = "GET_PINCODE_LIST_ALL";
export const GET_CAMPAIGN_PIXEL_LIST = "GET_CAMPAIGN_PIXEL_LIST";


// ========== advertiser action type's ===========
export const CREATE_ADVERTISER = "CREATE_ADVERTISER";
export const ADVERTISER_LIST = "ADVERTISER_LIST";
export const POST_MEDIA_CREATE_ACTION = "POST_MEDIA_CREATE_ACTION";
export const UPDATE_ADVERTISER = "UPDATE_ADVERTISER";
export const ADVERTISERS_WITHOUT_ACCOUNTS = "ADVERTISERS_WITHOUT_ACCOUNTS";
export const EDIT_ADVERTISER_LIST = "EDIT_ADVERTISER_LIST";

// ============ primary and second goals ============
export const GET_PRIMARY_GOAL = "GET_PRIMARY_GOAL";
export const GET_SECONDARY_GOAL = "GET_SECONDARY_GOAL";


// ============== RO API IMPLEMENTATION =========
export const CREATE_RO = "CREATE_RO";
export const FETCH_RO_LIST = "FETCH_RO_LIST";
export const FETCH_RO_LIST_NAMES = "FETCH_RO_LIST_NAMES";
export const GET_RO_LIST_BY_ID = "GET_RO_LIST_BY_ID";
export const UPDATE_RO = "UPDATE_RO";
export const CREATE_MAP_RO = "CREATE_MAP_RO";
export const MAP_EXISTING_RO = "MAP_EXISTING_RO";
export const GET_UNMAPPED_CAMPAIGN_COUNT = "GET_UNMAPPED_CAMPAIGN_COUNT"

// ============ campaign audience api implementation ==========
export const GOOGLE_AUDIENCE_LIST = "GOOGLE_AUDIENCE_LIST";
export const DV360_AUDIENCE_LIST = "DV360_AUDIENCE_LIST";
export const FACEBOOK_AUDIENCE_LIST = "FACEBOOK_AUDIENCE_LIST";

// =============== campagin api implementation ===========
export const CAMPAIGN_CREATION = "CAMPAIGN_CREATION";
export const CAMPAIGN_LIST_BY_ID = "CAMPAIGN_LIST_BY_ID";
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";

// Admin margin screen

export const GET_ADVERTISER_LIST_ADMINMARGIN = 'GET_ADVERTISER_LIST_ADMINMARGIN';

export const GET_PLATFORM_WISE_DATA = 'GET_PLATFORM_WISE_DATA';

export const GET_CAMPAIGN_LIST_ADMINMARGIN = 'GET_CAMPAIGN_LIST_ADMINMARGIN'

export const GET_ALL_LIST_ADMIN_MARGIN = 'GET_ALL_LIST_ADMIN_MARGIN';

export const POST_CREATE_ADMIN_MARGIN = 'POST_CREATE_ADMIN_MARGIN';

export const GET_ADMIN_MARGIN_LIST_BY_ID = 'GET_ADMIN_MARGIN_LIST_BY_ID';

export const POST_UPDATE_ADMIN_MARGIN = 'POST_UPDATE_ADMIN_MARGIN';


export const GET_ALL_LIST_MARGIN = 'GET_ALL_LIST_MARGIN';

export const POST_CREATE_MARGIN = 'POST_CREATE_MARGIN';

export const GET_MARGIN_LIST_BY_ID = 'GET_MARGIN_LIST_BY_ID';

export const POST_UPDATE_MARGIN = 'POST_UPDATE_MARGIN';

export const DOMAIN_REPORT = "DOMAIN_REPORT";

export const DEMO_GRAPHIC_DOWNLOAD_REPORT = "DEMO_GRAPHIC_DOWNLOAD_REPORT";

export const DEVICE_REPORT = "DEVICE_REPORT";

export const DEVICE_REPORT_DOWNLOAD = "DEVICE_REPORT_DOWNLOAD";

export const UPDATE_CAMPAIGN_STATUS = "UPDATE_CAMPAIGN_STATUS";

export const RECO_IMPLEMENTATION = "RECO_IMPLEMENTATION";

// MARGIN module Type here

// GET_OPTIMIZER_RECOMMENDATION_LIST




// payment

export const HISTORY_LIST = "HISTORY_LIST";

export const ADD_AMMOUNT_CREDIT = "ADD_AMMOUNT_CREDIT";

export const ADD_PAYMENT_DATA = "ADD_PAYMENT_DATA";

export const INVOICE_TENANT_LIST = "INVOICE_TENANT_LIST";

export const EDIT_INVOICE_TENANT_LIST = "EDIT_INVOICE_TENANT_LIST";

export const TENTITIVE_INVOICE_LIST = "TENTITIVE_INVOICE_LIST";

export const INVOICE_DETAILS_LIST = "INVOICE_DETAILS_LIST";

export const BILLING_HISTORY = "BILLING_HISTORY"

export const INVOICE_DETAIL_LIST_ALL = "INVOICE_DETAIL_LIST_ALL"

export const TENANT_INVOCE_DETAILS = "TENANT_INVOCE_DETAILS"

export const CURRENT_MONTH_INVOICE_DETAIL = "CURRENT_MONTH_INVOICE_DETAIL"

export const POST_UPDATE_TENANT_INVOICE_DETAILS = "POST_UPDATE_TENANT_INVOICE_DETAILS"

export const SUBSCRIPTION_LIST_ALL = "SUBSCRIPTION_LIST_ALL"

export const SUBSCRIPTION_LIST_TRAIL = "SUBSCRIPTION_LIST_TRAIL"

export const MANAGE_SERVICE_TRAIL = "MANAGE_SERVICE_TRAIL"

export const POST_RECHARGE_SUBSCRIPTION = "POST_RECHARGE_SUBSCRIPTION"

export const SUBSCRIPTION_HISTORY_LIST_ALL = "SUBSCRIPTION_HISTORY_LIST_ALL"

export const SUBSCRIPTION_CURRENT_LIST_ALL = "SUBSCRIPTION_CURRENT_LIST_ALL"

export const GET_SET_ALERT_LIST_ALL = "GET_SET_ALERT_LIST_ALL"

export const POST_SET_ALERTS_CREATE = "POST_SET_ALERTS_CREATE"

export const POST_SET_ALERTS_UPDATE = "POST_SET_ALERTS_UPDATE"

export const GET_PAYMENT_PREFRENCE = "GET_PAYMENT_PREFRENCE"

export const PAYMENT_ACCESS_ACTION_TYPE = "PAYMENT_ACCESS_ACTION_TYPE"





// google signin ==

export const GENRATE_GOOGLE_URL = "GENRATE_GOOGLE_URL";

export const POST_GOOGLE_VERIFICATION_URL = "POST_GOOGLE_VERIFICATION_URL";

export const POST_GOOGLE_ACCOUNTS = "POST_GOOGLE_ACCOUNTS";

// tik tok sign in ==

export const POST_TIKTOK_VERIFICATION_URL = "POST_TIKTOK_VERIFICATION_URL";

//

export const POST_CAMPAIGN = "POST_CAMPAIGN";

export const POST_SYNC_CAMPAIGN = "POST_SYNC_CAMPAIGN";

export const POST_SYNC_CAMPAIGN_DV_360 = "POST_SYNC_CAMPAIGN_DV_360";

export const POST_SYNC_CAMPAIGN_ION = "POST_SYNC_CAMPAIGN_ION";

// ========== facebook implementation ======
export const GET_FACEBOOK_USER_ACCOUNTS = "GET_FACEBOOK_USER_ACCOUNTS";

export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const GET_CAMPAIGN = "GET_CAMPAIGN";

export const FACEBOOK_SYNC_CAMPAIGN = "FACEBOOK_SYNC_CAMPAIGN"; 



// ion
export const POST_ION_CAMPAIGN = "POST_ION_CAMPAIGN";

export const POST_CAMPAIGN_NAMLIST = "POST_CAMPAIGN_NAMLIST";

export const AUD_HIERARCHY_LIST = "AUD_HIERARCHY_LIST";

export const GET_NEW_SEGMENT_LIST_ALL = "GET_NEW_SEGMENT_LIST_ALL";

export const GET_CAMPAIGN_CATEGORY_LIST = "GET_CAMPAIGN_CATEGORY_LIST";

export const GET_CAMPAIGN_SUB_CATEGORY_LIST = "GET_CAMPAIGN_SUB_CATEGORY_LIST";

export const UPDATE_ION_CAMPAIGN_STATUS = "UPDATE_ION_CAMPAIGN_STATUS";


// dv360
export const GET_DV360_URL = "GET_DV360_URL";
export const POST_DV360_VERIFICATION_URL = "POST_DV360_VERIFICATION_URL";


// ======= gam ======
export const GET_GAM_URL = "GET_GAM_URL";
export const POST_GAM_VERIFICATION_URL = "POST_GAM_VERIFICATION_URL";
export const POST_GAM_CAMPAIGN_LIST = "POST_GAM_CAMPAIGN_LIST";
export const POST_GAM_SYNC_CAMPAIGN = "POST_GAM_SYNC_CAMPAIGN";


export const POST_TIKTOK_LOCATION = "POST_TIKTOK_LOCATION";

export const GET_GEO_LOCATION = "GET_GEO_LOCATION"

export const GET_FACEBOOK_REFETCH_CAMPAIGN = "FACEBOOK_REFETCH_CAMPAIGN";

export const POST_DV360_ACCOUNTS = "POST_DV360_ACCOUNTS";

export const POST_DV360_CAMPAIGN = "POST_DV360_CAMPAIGN";

export const GET_FACEBOOK_ACCOUNTS = "GET_FACEBOOK_ACCOUNTS";

export const GET_LIST_DV_360 = "GET_LIST_DV_360";

export const GET_ACCOUNT_ID_DV = "GET_ACCOUNT_ID_DV";


export const POST_SUBSCRIBE = "POST_SUBSCRIBE";

export const PLAN_LIST_ALL = "PLAN_LIST_ALL"

export const POST_CUSTOM_PLANS = "POST_CUSTOM_PLANS";

export const SUPPORT_CONTACT = "SUPPORT_CONTACT";

export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
